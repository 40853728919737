import React from "react";
import css from "./Testimonials.module.css";
import hero from "../../assets/testimonialHero.png";
import {Swiper, SwiperSlide} from "swiper/react";
import {TestimonialsData} from '../../data/testimonials'
const Testimonials = () => {
  return (
    <div className={css.testimonials}>
      <div className={css.wrapper}>
        <div className={css.container}>
          <span>Top Rated</span>
          <span>
            SEEDILY SAY HAS SUITABLE DISPOSAL AND BOY. EXERCISE JOY MAN CHILDREN
            REJOICED.
          </span>
        </div>

        <img src={hero} alt="hero" />
        <div className={css.container}>
          <span>100K</span>
          <span>HAPPY CUSTOMERS WITH US</span>
        </div>
      </div>
      <div className={css.reviews}>Reviews</div>
      <div className={css.carousel}>
        <Swiper slidesPerView={3} slidesPerGroup={1} spaceBetween={20} className={css.tCarousel}
        breakpoints={{
          856: {
            slidesPerView: 3
          },
          640: {
            slidesPerView: 2
          },
          0: {
            slidesPerView: 1
          }
        }}
        >
        {
            TestimonialsData.map((testimonials, i)=> (
                <SwiperSlide>
                    <div className={css.testimonialss}>
                        <img src={testimonials.image} alt="" />
                        <span>{testimonials.comment}</span>
                        <hr />
                        <span>{testimonials.name}</span>
                    </div>
                </SwiperSlide>
            ))
        }
        </Swiper>
      </div>
    </div>
  );
};

export default Testimonials;
