import React from "react";
import css from "./Hero.module.css";
import heroImage from "../../assets/hero.png";
import { RiShoppingBagFill } from "react-icons/ri";
import { BsArrowRight } from "react-icons/bs";
import { motion } from "framer-motion";

const Hero = () => {
  const transition = { duration: 3, type: "spring" };
  return (
    <div className={css.container}>
      {/*  left side */}

      <div className={css.h_sides}>
        <span className={css.text1}>Skin protection products</span>
        <div className={css.text2}>
          <span>Trendy Collection</span>
          <span>
            Seedily say has suitable disposal and boy. Exercise joy man children
            rejoiced.
          </span>
        </div>
      </div>
      {/* let left side */}

      {/*  Middle side */}

      <div className={css.wrapper}>
        <motion.div
          initial={{ bottom: "2rem" }}
          whileInView={{ bottom: "0rem" }}
          transition={transition}
          className={css.bluecircle}
        ></motion.div>
        <motion.img
          initial={{ bottom: "-2rem" }}
          whileInView={{ bottom: "0rem" }}
          transition={transition}
          src={heroImage}
          width={600}
          alt="img"
        />

        <motion.div
        initial={{ right: "-4%" }}
          whileInView={{ right: "2%" }}
          transition={transition}
         className={css.cart2}>
          <RiShoppingBagFill />
          <div className={css.signup}>
            <span>Best Signup Offers </span>
            <div>
              <BsArrowRight />
            </div>
          </div>
        </motion.div>
      </div>
      {/*  Middle side */}

      {/*  Right side */}

      <div className={css.h_sides}>
        <div className={css.traffic}>
          <span>1.5m</span>
          <span>Monthly traffic</span>
        </div>
        <div className={css.customer}>
          <span>100k</span>
          <span>Happy Cutomers</span>
        </div>
      </div>

      {/*  Right side */}
    </div>
  );
};

export default Hero;
